import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { graphql, Link, useStaticQuery, navigate } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Button from "../components/shared/Button";
import { Menu } from "./Path";
import { MDXProvider, mdx } from "@mdx-js/react";
import mdxComponents from "../components/mdxComponents";
import { LessonNavigation } from "./Lesson";
import BreadCrumbs from "./lesson/Breadcrumbs";

export const pageQuery = graphql`
  query ($id: String!) {
    lesson(id: { eq: $id }) {
      title
      slug
      next
      prev
      lessonIndex
      lessonCount
      menu {
        title
        slug
      }
      childMdx {
        body
      }
      parent {
        ... on path {
          id
          title
          slug
        }
      }
    }
  }
`;

function Lesson({
  data: {
    lesson: {
      title,
      slug,
      prev,
      next,
      lessonIndex,
      lessonCount,
      menu,
      childMdx: { body },
      parent: { title: pathTitle, slug: pathSlug },
    },
  },
}) {
  return (
    <Layout>
      <MDXProvider components={mdxComponents}>
        <Seo title={title} />
        <BreadCrumbs
          path={pathTitle}
          showMenu={false}
          onClickPath={() => {
            navigate(pathSlug);
          }}
          onClickTitle={() => {}}
          title={title}
          showCrumbs={true}
        />
        <MDXRenderer>{body}</MDXRenderer>
        <LessonNavigation
          {...{
            showMenu: false,
            showMenuIcon: false,
            lessonIndex,
            lessonCount,
            prev,
            next,
            setShowMenu: () => {},
          }}
        />
      </MDXProvider>
    </Layout>
  );
}

export default Lesson;
