import React from "react";
import { ChallengeGrid } from "./challenges/ChallengeGrid";

export default {
  iframe: (props) => (
    <div
      style={{ overflow: "hidden", paddingTop: "56.25%", position: "relative" }}
    >
      <iframe
        {...props}
        style={{
          width: "100%",
          height: "500px",
          border: "0",
          borderRadius: "4px",
          overflow: "hidden",
          position: "absolute",
          left: 0,
          top: 0,
        }}
        {...(props.sandbox
          ? {
              src: `https://codesandbox.io/embed/${props.sandbox}?fontsize=14&hidenavigation=1&theme=dark`,
              title: props.sandbox,
              allow:
                "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr",
              sandbox:
                "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts",
            }
          : {})}
      ></iframe>
    </div>
  ),
  ChallengeGrid,
};
