import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const ChallengeLink = styled(Link)`
  padding: 10px;
  background-color: #0048be;
  color: white;
  display: inline-block;
  &:hover {
    color: yellow;
  }
`;

const NUM_CHALLENGES = 25;

export function ChallengeGrid() {
  const links = [];
  for (let i = 1; i < NUM_CHALLENGES + 1; i++) {
    links.push(<ChallengeLink to={`/challenges/${i}`}>{i}</ChallengeLink>);
  }
  return (
    <div>
      <p>Challenge Links</p>
      {links}
    </div>
  );
}
